import { useCallback, useEffect, useState } from 'react';

import { useCopyToClipboard as useTsCopyToClipboard } from 'usehooks-ts';

const useCopyToClipboard = ({ copyInterval = 2000 }: { copyInterval?: number } = {}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [copiedValue, copy] = useTsCopyToClipboard();
  const copyToClipboard = useCallback(
    async (value: string) => {
      const success = await copy(value);

      if (success) {
        setIsCopied(true);
      }

      return success;
    },
    [copy],
  );

  useEffect(() => {
    if (!copyInterval || copyInterval <= 0) {
      return;
    }

    const timeoutId = window.setTimeout(() => {
      setIsCopied(false);
    }, copyInterval);

    return () => {
      clearInterval(timeoutId);
    };
  }, [isCopied, copyInterval]);

  return {
    isCopied,
    copiedValue,
    copyToClipboard,
  };
};

export default useCopyToClipboard;
